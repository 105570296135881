// Circle Pointer Module
function initCirclePointer() {
    const circlePointer = document.querySelector('.circle-pointer');
    let mouseX = 0;
    let mouseY = 0;
    let posX = 0;
    let posY = 0;
    const delay = 0.3; // Delay in seconds

    // Track mouse movement
    function trackMouse(e) {
        mouseX = e.clientX;
        mouseY = e.clientY;
    }

    // Animation loop to move the circle pointer
    function animatePointer() {
        posX += (mouseX - posX) * delay;
        posY += (mouseY - posY) * delay;

        circlePointer.style.left = `${posX}px`;
        circlePointer.style.top = `${posY}px`;

        requestAnimationFrame(animatePointer);
    }

    // Add hover effects on anchor and button elements
    function addHoverEffects() {
        document.querySelectorAll('a, button, .menu-icon').forEach(element => {
            element.addEventListener('mouseenter', () => {
                circlePointer.classList.add('scale');
                circlePointer.style.backgroundColor = 'rgba(var(--yellow-rgb), 0.8)';
            });

            element.addEventListener('mouseleave', () => {
                circlePointer.classList.remove('scale');
                circlePointer.style.backgroundColor = 'rgba(var(--green-rgb), 0.6)';
            });
        });
    }

    document.addEventListener('mousemove', trackMouse);
    animatePointer();
    addHoverEffects();
}

function initPageTransition() {
    function transition() {
        // Simulate a loading delay for testing purposes
        setTimeout(() => {
            // Add the 'loaded' class to the body to trigger the CSS transition
            document.body.classList.add("loaded");
        }, 1000); // 3000 milliseconds = 3 seconds delay
    }

    transition();
}

// GSAP
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

// Register ScrollTrigger with GSAP
gsap.registerPlugin(ScrollTrigger);

function initProductsScrollAnimation() {
    // Create a GSAP timeline with ScrollTrigger
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".products-row", // Element that triggers the animation
            start: "bottom 80%", // Start animation when the top of the element reaches 80% of the viewport
            end: "bottom 20%", // End animation when the bottom of the element reaches 20% of the viewport
            scrub: 0.5, // Smooth scrub animation
            markers: false, // Debug markers, set to false when done
        }
    });

    // Animate the elements with smooth transitions
    tl.fromTo(".products-row1", { x: 0, opacity: 1 }, { x: 200, opacity: 0, duration: 1.2, ease: "power1.inOut" })
        .fromTo(".products-row2", { x: 0, opacity: 1 }, { x: -200, opacity: 0, duration: 1.2, ease: "power1.inOut" })
}

function initMakerSpaceScrollAnimation() {
    // Create a GSAP timeline with ScrollTrigger
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".makerspace-trigger",
            start: "top 80%",
            end: "bottom 20%",
            scrub: 0.5, // Smoother scrub effect
            markers: false, // Set to false when done
        }
    });

    // Smooth animation with easing
    tl.from(".makerspace-tile1", { x: -100, opacity: 0.25, duration: 1.2, ease: "power2.out" }, "0")
        .from(".makerspace-tile2", { y: -100, opacity: 0.25, duration: 1.2, ease: "power2.out" }, "0")
        .from(".makerspace-tile3", { x: 100, opacity: 0.25, duration: 1.2, ease: "power2.out" }, "0")
        .from(".makerspace-tile4", { y: 100, opacity: 0.25, duration: 1.2, ease: "power2.out" }, "0");
}

function initSavvyScrollAnimation() {
    // Create a GSAP timeline with ScrollTrigger
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".savvy-trigger",
            start: "bottom 80%",
            end: "bottom 20%",
            scrub: 0.5, // Smoother scrub effect
            markers: false, // Set to false when done
        }
    });

    // Smooth animation with easing
    tl.from(".savvy-tile1", { y: -100, opacity: 0.25, duration: 1.2, ease: "power2.out" }, "0")
        .from(".savvy-tile2", { x: 100, opacity: 0.25, duration: 1.2, ease: "power2.out" }, "0")
        .from(".savvy-tile3", { x: -100, opacity: 0.25, duration: 1.2, ease: "power2.out" }, "0");
}

function initEducastScrollAnimation() {
    // Create a GSAP timeline with ScrollTrigger
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".educast-trigger",
            start: "bottom 80%",
            end: "bottom 20%",
            scrub: 0.5, // Smoother scrub effect
            markers: false, // Set to false when done
        }
    });

    // Smooth animation with easing
    tl.from(".educast-tile1", { y: -100, opacity: 0.25, duration: 1.2, ease: "power2.out" }, "0")
        .from(".educast-tile2", { x: 100, opacity: 0.25, duration: 1.2, ease: "power2.out" }, "0")
        .from(".educast-tile3", { x: -100, opacity: 0.25, duration: 1.2, ease: "power2.out" }, "0");
}

function initEvesmsScrollAnimation() {
    // Create a GSAP timeline with ScrollTrigger
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".evesms-trigger",
            start: "bottom 80%",
            end: "bottom 20%",
            scrub: 0.5, // Smoother scrub effect
            markers: false, // Set to false when done
        }
    });

    // Smooth animation with easing
    tl.from(".evesms-tile1", { y: -100, opacity: 0.25, duration: 1.2, ease: "power2.out" }, "0")
        .from(".evesms-tile2", { x: 100, opacity: 0.25, duration: 1.2, ease: "power2.out" }, "0")
        .from(".evesms-tile3", { x: -100, opacity: 0.25, duration: 1.2, ease: "power2.out" }, "0");
}

function initSimsScrollAnimation() {
    // Create a GSAP timeline with ScrollTrigger
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".sims-trigger",
            start: "bottom 80%",
            end: "bottom 20%",
            scrub: 0.5, // Smoother scrub effect
            markers: false, // Set to false when done
        }
    });

    // Smooth animation with easing
    tl.from(".sims-tile1", { y: -100, opacity: 0.25, duration: 1.2, ease: "power2.out" }, "0")
        .from(".sims-tile2", { x: 100, opacity: 0.25, duration: 1.2, ease: "power2.out" }, "0")
        .from(".sims-tile3", { x: -100, opacity: 0.25, duration: 1.2, ease: "power2.out" }, "0");
}

document.addEventListener("DOMContentLoaded", () => {
    initProductsScrollAnimation();
    initMakerSpaceScrollAnimation();
    initSavvyScrollAnimation();
    initEducastScrollAnimation();
    initEvesmsScrollAnimation();
    initSimsScrollAnimation();
    initCirclePointer();
    initPageTransition();
});
