// Bootstrap
import './bootstrap';

// Locomotive
import LocomotiveScroll from 'locomotive-scroll';

// Initialize LocomotiveScroll
const locomotiveScroll = new LocomotiveScroll();

// Custom script
import './custom';
